import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Assets,
  Project,
  TimelineItem,
  TimelineListType,
  projectService,
  getProjectFromDto,
} from "../service/projectService";
import {v4} from "uuid";
import {usePlayerContext} from "./PlayerContext";
import {useIsMutating, useMutation} from "react-query";
import throttle from "lodash/throttle";
import {
  CloudinaryUploadError,
  CloudinaryUploadResult,
  getAssetsFromCloudinaryAsset,
} from "./Cloudinary";
import {mapAssetsToTimelineAtIndex} from "../helpers/mapAssetsToTimelineAtIndex";
import {useBeforeUnload} from "react-use";

export type Context = Readonly<{
  project: Project;
  isDirty: boolean;
  timeline: TimelineListType;
  updateTimeline: React.Dispatch<React.SetStateAction<TimelineListType>>;
  assets: Assets;
  sectionsCount: number;
  activeItem: TimelineItem;
  activeIndex: number;
  addPictures: (
    error: CloudinaryUploadError,
    result: CloudinaryUploadResult,
    index?: number,
  ) => void;
  submittedAt?: string;
  setSubmittedAt?: (date: string) => void;
}>;

export const useTimelineContext = (): Context => useContext(TimelineContext);
export const TimelineContext = createContext<Context>({
  project: getProjectFromDto(),
  isDirty: false,
  activeIndex: 0,
  activeItem: {id: ""},
  addPictures(): void {},
  sectionsCount: 0,
  updateTimeline(): void {},
  timeline: [],
  assets: {},
});
const throttleMs = 2000;

const generateEmptyTimeline = (n: number = 1): TimelineItem[] =>
  new Array(n).fill(null).map((item, index) => ({
    id: v4(),
  }));

export const TimelineContextProvider = ({
  children,
  project,
}: PropsWithChildren<{project: Project}>) => {
  const isMutating = useIsMutating();
  const [isDirty, setDirty] = useState(false);

  useBeforeUnload(
    isDirty || isMutating > 0,
    "You have unsaved changes, are sure you want to leave the page?",
  );

  const {playedInSeconds, durationInSeconds} = usePlayerContext();
  const [timeline, setTimeline] = useState<TimelineListType>(
    project.timeline ?? [],
  );
  const [assets, setAssets] = useState<Assets>(project.assets ?? {});
  const [sectionsCount, setSectionsCount] = useState<number>(0);
  const [submittedAt, setSubmittedAt] = useState("");

  useEffect(() => {
    if (project?.timeline && project?.timeline?.length > 0) {
      setTimeline(project.timeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, project?.timeline?.length, project.updatedAt]);

  const timelineLength = project.timeline?.length ?? 0;
  useEffect(() => {
    const length =
      Math.floor(durationInSeconds / SECTION_LENGTH_IN_SECONDS) ?? 0;

    if (timelineLength === 0 && length > 0) {
      setTimeline(generateEmptyTimeline(length));
    }

    if (timelineLength !== 0 && timelineLength < length) {
      setTimeline((timeline) => [
        ...timeline,
        ...generateEmptyTimeline(length - timelineLength),
      ]);
    }

    setSectionsCount(length);
  }, [timelineLength, durationInSeconds, project.timeline]);

  useEffect(() => {
    if (Object.keys(project.assets).length > 0) {
      setAssets(project.assets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, Object.keys(project.assets).length, project.updatedAt]);

  const postTimeline = useMutation(projectService.postTimeline);
  const postAssets = useMutation(projectService.postAssets);

  const throttledAddAssets = useMemo(
    () =>
      throttle((assets: Assets) => {
        setDirty(false);
        if (project.id && Object.keys(assets).length > 0) {
          postAssets.mutate({projectId: project.id, assets});
        }
      }, throttleMs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id, throttleMs],
  );

  const throttledPutTimeline = useMemo(
    () =>
      throttle((timeline: TimelineListType) => {
        setDirty(false);
        if (timeline.some((item) => item.assetId)) {
          postTimeline.mutate({projectId: project.id, timeline: timeline});
        }
      }, throttleMs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id, throttleMs],
  );

  const updateTimeline: React.Dispatch<React.SetStateAction<TimelineListType>> =
    useCallback(
      (value) => {
        setDirty(true);
        setTimeline(value);
        setTimeline((timeline) => {
          throttledPutTimeline(timeline);
          return timeline;
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [throttledPutTimeline],
    );

  const addPictures = useCallback(
    (
      error: CloudinaryUploadError,
      result: CloudinaryUploadResult,
      index: number = 0,
    ) => {
      if (result.event !== "queues-end") {
        return;
      }

      const uploadedAssets = getAssetsFromCloudinaryAsset(result.info.files);

      setDirty(true);
      setAssets((assets) => {
        const nextAssets = {
          ...assets,
          ...uploadedAssets,
        };

        throttledAddAssets(nextAssets);

        return nextAssets;
      });

      updateTimeline(
        mapAssetsToTimelineAtIndex({assets: uploadedAssets, timeline, index}),
      );
    },
    [timeline, setAssets, updateTimeline, throttledAddAssets],
  );

  const {activeIndex, activeItem} = useMemo(() => {
    const activeIndex =
      Math.floor(playedInSeconds / SECTION_LENGTH_IN_SECONDS) % sectionsCount ||
      0;
    const activeItem = timeline[activeIndex];

    return {activeIndex, activeItem};
  }, [playedInSeconds, timeline, sectionsCount]);

  const value = useMemo(
    () => ({
      project,
      isDirty,
      timeline,
      updateTimeline,
      assets,
      sectionsCount,
      activeIndex,
      activeItem,
      addPictures,
      submittedAt,
      setSubmittedAt,
    }),
    [
      project,
      isDirty,
      timeline,
      updateTimeline,
      assets,
      sectionsCount,
      activeIndex,
      activeItem,
      addPictures,
      submittedAt,
      setSubmittedAt,
    ],
  );

  return (
    <TimelineContext.Provider value={value}>
      {children}
    </TimelineContext.Provider>
  );
};

export const SECTION_LENGTH_IN_SECONDS = 6;
