import axios from "./axiosInstance";
import {ProjectState} from "../components/AdminTable";

export type stateHistory = {
  state: ProjectState;
  timestamp: string;
  detail?: string;
};

export const adminService = {
  search: async (param: {
    phrase: string | null;
    lastEvaluatedKey: string | null;
    pageSize: number | null;
  }) => {
    const {data} = await axios.post("/api/projects", {
      phrase: param.phrase,
      page: {
        lastEvaluatedKey: param.lastEvaluatedKey,
        pageSize: param.pageSize,
      },
    });
    return data;
  },

  getProjectStateHistory: (projectId: string) =>
    axios.get<stateHistory[]>(`api/projects/${projectId}/state-history`),

  lock: async (id: string) => axios.patch(`/api/projects/${id}/lock`),

  unlock: async (id: string) => axios.patch(`/api/projects/${id}/unlock`),

  updateRemainingRenders: async (param: {
    id: string;
    remainingRenders: number;
  }) =>
    axios.patch(`/api/projects/${param.id}`, {
      remainingRenders: param.remainingRenders,
    }),
};
