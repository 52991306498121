import {v4} from "uuid";
import {Assets, TimelineListType} from "../service/projectService";

export const mapAssetsToTimelineAtIndex = ({
  assets,
  timeline,
  index = 0,
}: {
  assets: Assets;
  timeline: TimelineListType;
  index?: number;
}) => {
  const assetIds = Object.keys(assets);

  const result = Array.from(timeline).map((item, itemIndex) => {
    if (item.assetId || assetIds.length === 0) {
      return item;
    }

    if (itemIndex < index) {
      return item;
    }

    const assetId = assetIds.shift();
    const publicId = assetId ? assets[assetId].publicId : "";

    return {
      ...item,
      assetId,
      publicId,
    };
  });

  if (assetIds.length > 0) {
    return [
      ...result,
      ...assetIds.map((assetId) => ({
        id: v4(),
        assetId,
        publicId: assetId ? assets[assetId].publicId : "",
      })),
    ];
  }

  return result;
};
