import React from "react";
import styled from "@emotion/styled";
import {rem} from "polished";

const defaultProps = {
  Footer: styled.footer`
    background: #000000;

    position: relative;
    width: 100%;
    padding: 1rem 0;
  `,
  Content: styled.div`
    color: white;
    font-family: National-SemiBold, sans-serif;
    text-align: center;

    @media screen and (min-width: ${rem(1200)}) {
      margin: auto;
      max-width: ${rem(1024)};
    }

    @media screen and (min-width: ${rem(768)}) {
      text-align: left;
      margin-left: 1rem;
    }
  `,
};

type Props = Readonly<typeof defaultProps & {handleClick?: () => void}>;
type Children = (props: Props) => JSX.Element;

export const Footer = ({children, ...props}: {children: Children} & Props) => {
  return children({...props});
};

Footer.defaultProps = {
  ...defaultProps,
  children: ({Footer, Content}: Props) => (
    <Footer>
      <Content>© Songfinch 2021</Content>
    </Footer>
  ),
};
