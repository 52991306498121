import React, {useState} from "react";
import {
  BugOutlined,
  CheckOutlined,
  EditOutlined,
  FlagOutlined,
  HistoryOutlined,
  LockOutlined,
  PlayCircleOutlined,
  PushpinOutlined,
  AppstoreAddOutlined,
  SoundOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {adminService} from "../service/adminService";
import {Alert, Button, Input, Table} from "antd";
import styled from "@emotion/styled";
import {RelativeDateTime} from "./RelativeDateTime";
import {LockUnlockFixButton} from "./LockUnlockFixButton";
import {P} from "./Typography";
import {VideoUrlHost} from "../service/projectService";

export enum ProjectState {
  created = "CREATED",
  updated = "UPDATED",
  inprogress = "IN_PROGRESS",
  released = "RELEASED",
  failed = "FAILED",
  locked = "LOCKED",
  disabled = "DISABLED",
}

export const ProjectStateIcons: Record<string, any> = {
  [ProjectState.created]: FlagOutlined,
  [ProjectState.updated]: AppstoreAddOutlined,
  [ProjectState.inprogress]: PushpinOutlined,
  [ProjectState.released]: CheckOutlined,
  [ProjectState.failed]: BugOutlined,
  [ProjectState.locked]: LockOutlined,
  [ProjectState.disabled]: LockOutlined,
};

export const getProjectStateIcon = (state: string) =>
  ProjectStateIcons[state] ?? QuestionOutlined;

export type Content = {
  /* Project ID - "78d7dfe4-728b-4675-ab60-00b87f2edf44" */
  id: string;
  /* Customer Email - "some_1@email.com" */
  customerEmail: string;
  /* Song Title - "1st Great Song"*/
  songTitle: string;
  /* Artist Name - "John Smith"*/
  artistName: string;
  /* Song URL - "http://s3.aws.com/song/great_song_1.mp3"*/
  songUrl: string;

  videoUrl: string | null;
  videoUrlHost: VideoUrlHost | null;

  /* Project State - "CREATED" */
  state: ProjectState;
  /* Remaining renders - 3 */
  remainingRenders: number;
  /* createdAt - "2021-03-05T13:13:56Z" */
  createdAt: string;
  /* updatedAt - "2021-03-05T13:13:56Z" */
  updatedAt: string;
};

type VideoProjectList = {
  lastEvaluatedKey: string;
  hasMore: boolean;
  content: Content[];
};

export const ProjectTable = () => {
  const pageSize = 25;

  const columns = [
    {
      title: "Song Title",
      dataIndex: "songTitle",
      key: "songTitle",
      render: (songTitle: string, row: Content) => (
        <>
          <P>{songTitle}</P>
          <P>{row.artistName}</P>
        </>
      ),
      sorter: (a: any, b: any) => a.songTitle.localeCompare(b.songTitle),
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      sorter: (a: any, b: any) =>
        a.customerEmail.localeCompare(b.customerEmail),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <RelativeDateTime dateTime={new Date(createdAt)} />
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) =>
        updatedAt && <RelativeDateTime dateTime={new Date(updatedAt)} />,
    },
    {
      title: "Remaining Renders",
      dataIndex: "remainingRenders",
      key: "remainingRenders",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state: ProjectState) => {
        const Icon = getProjectStateIcon(state);

        return (
          <Row>
            <Icon />
            {state.toLowerCase()}
          </Row>
        );
      },
    },
    {
      dataIndex: "songUrl",
      key: "songUrl",
      render: (songUrl: string) => (
        <a
          href={`${songUrl}`}
          target={"_blank"}
          rel={"noopener noreferrer"}
          title={"Link to audio file"}
        >
          <SoundOutlined />
        </a>
      ),
    },
    {
      dataIndex: "videoUrl",
      key: "videoUrl",
      render: (videoUrl: string, row: Content) =>
        videoUrl && (
          <a
            href={`${videoUrl}`}
            target={"_blank"}
            rel={"noopener noreferrer"}
            title={
              row.videoUrlHost === VideoUrlHost.CLOUDINARY
                ? "Link to Cloudinary video file"
                : row.videoUrlHost === VideoUrlHost.S3
                ? "Link to S3 video file"
                : "Link to video file"
            }
          >
            <PlayCircleOutlined />
          </a>
        ),
    },
    {
      dataIndex: "id",
      key: "id",
      render: (id: string, row: Content) => (
        <LockUnlockFixButton
          project={row}
          onLock={() => onLock(id)}
          onUnlock={() => onUnlock(id)}
          onFix={() => onLock(id)}
        />
      ),
    },
    {
      dataIndex: "id",
      key: "id",
      render: (id: string, row: Content) =>
        [ProjectState.inprogress, ProjectState.disabled].includes(row.state) ? (
          <EditOutlined disabled={true} />
        ) : (
          <Link to={`/?projectId=${id}`} title={"Edit project"}>
            <EditOutlined />
          </Link>
        ),
    },
    {
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <Link
          to={`/admin/project/${id}/state-history`}
          title={"Song project state history"}
        >
          <HistoryOutlined />
        </Link>
      ),
    },
  ];

  const [paginationKeys, setPaginationKeys] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [isError, setError] = useState(false);

  const useVideoProjectList = (param: {
    phrase: string | null;
    lastEvaluatedKey: string | null;
    pageSize: number | null;
  }) =>
    useQuery<VideoProjectList, Error>(
      ["useVideoProjectList", param],
      () => adminService.search(param),
      {
        onError: () => setError(true),
      },
    );

  const lastEvaluatedKey =
    paginationKeys.length > 0
      ? paginationKeys[paginationKeys.length - 1]
      : null;

  const {data, isFetching} = useVideoProjectList({
    phrase: searchPhrase,
    lastEvaluatedKey: lastEvaluatedKey,
    pageSize: pageSize,
  });

  const lockProject = useMutation(adminService.lock, {
    onSuccess: () => invalidateTableContent(),
  });
  const unlockProject = useMutation(adminService.unlock, {
    onSuccess: () => invalidateTableContent(),
  });

  const loadPrevious = () => {
    const newPaginationKeys = Array.from(paginationKeys);
    newPaginationKeys.pop();
    setPaginationKeys(newPaginationKeys);
  };

  const loadNext = () => {
    if (data && data.lastEvaluatedKey) {
      const newPaginationKeys = Array.from(paginationKeys);
      newPaginationKeys.push(data.lastEvaluatedKey);
      setPaginationKeys(newPaginationKeys);
    }
  };

  const onSearch = (value: string) => {
    setSearchPhrase(value);
  };

  const onLock = (id: string) => {
    lockProject.mutate(id);
  };

  const onUnlock = (id: string) => {
    unlockProject.mutate(id);
  };

  const queryClient = useQueryClient();

  const invalidateTableContent = () => {
    queryClient.invalidateQueries([
      "useVideoProjectList",
      {
        phrase: searchPhrase,
        lastEvaluatedKey: lastEvaluatedKey,
        pageSize: pageSize,
      },
    ]);
  };

  return (
    <>
      {isError && (
        <Alert
          message="Failed to fetch the list of video projects"
          description="Please try again later."
          type="error"
          closable
        />
      )}
      <Search
        placeholder="Search video project"
        onSearch={onSearch}
        enterButton
      />
      <section>
        <Table
          dataSource={data === undefined ? [] : data.content}
          rowKey={(record) => record.id}
          pagination={{pageSize, hideOnSinglePage: true}}
          loading={isFetching}
          columns={columns}
        />

        <TableFooter>
          {paginationKeys.length > 0 && (
            <Button onClick={loadPrevious}>Previous</Button>
          )}
          {data && data.lastEvaluatedKey && (
            <Button onClick={loadNext}>Next</Button>
          )}
        </TableFooter>
      </section>
    </>
  );
};

const Search = styled(Input.Search)`
  margin: 1rem 0;
`;

const TableFooter = styled.footer`
  display: flex;
  margin: 1rem 0;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
