import React from "react";
import {BasePage} from "./BasePage";
import {useTitle} from "react-use";
import {P} from "../components/Typography";

const defaultProps = {
  ...BasePage.defaultProps,
};

export type Props = typeof defaultProps;

export const InRevisionPage = ({
  Header,
  H1,
  Wrapper,
  Footer,
  LargeLogo,
  ReturnButton,
  PageFooter,
}: Props) => {
  const title = "Song is being curated...";

  useTitle(title);

  return (
    <>
      <Header />
      <Wrapper>
        <LargeLogo src="/img/Revision.png" alt="Slideshow in revision" />

        <H1>{title}</H1>

        <Footer>
          <P>
            While some songs take seconds to correct, other need more time. You
            can close this tab, we will send you an email once your song is
            ready
          </P>

          <ReturnButton />
        </Footer>
      </Wrapper>

      <PageFooter />
    </>
  );
};

InRevisionPage.defaultProps = {
  ...defaultProps,
};
