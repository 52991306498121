import React from "react";
import {formatDistance, format, isToday} from "date-fns";

type Props = Readonly<{
  dateTime: Date;
  onlyToday?: boolean;
  dateFormat?: string;
}>;

const DAY_DATE_FORMAT = "MMMM d, yyyy";
const FULL_DATE = "MM/dd/yyyy";
const FULL_TIME = "hh:mm:ss aaa";
const FULL_DATE_TIME = `${FULL_DATE} ${FULL_TIME}`;

export const RelativeDateTime = ({dateTime, onlyToday, dateFormat}: Props) => (
  <time title={format(dateTime, FULL_DATE_TIME)}>
    {onlyToday
      ? renderWithDate(dateTime, dateFormat??DAY_DATE_FORMAT)
      : formatDistance(dateTime, Date.now(), {addSuffix: true})}
  </time>
);

RelativeDateTime.defaultProps = {
  onlyToday: true,
  dateFormat: DAY_DATE_FORMAT,
};

const renderWithDate = (dateTime: Date, dateFormat: string) =>
  isToday(dateTime)
    ? formatDistance(dateTime, Date.now(), {addSuffix: true})
    : format(dateTime, dateFormat);
