import React from "react";
import styled from "@emotion/styled";
import {rem} from "polished";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Link, useParams} from "react-router-dom";
import {Alert, Descriptions, Layout, Timeline, Typography} from "antd";
import {adminService} from "../service/adminService";
import {
  ClockCircleOutlined,
  EditOutlined,
  MinusOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {RelativeDateTime} from "../components/RelativeDateTime";
import {
  getProjectFromDto,
  ProjectDto,
  projectService,
  VideoUrlHost,
} from "../service/projectService";
import {TimelineContextProvider} from "../hooks/TimelineContext";

import {ValidityDisplay} from "../components/timeline/ValidityDisplay";
import {getProjectStateIcon, ProjectState} from "../components/AdminTable";
import {GhostButton} from "../components/Button";
import {LockUnlockFixButton} from "../components/LockUnlockFixButton";
import {P} from "../components/Typography";

export const AdminStateHistory = () => {
  const {id} = useParams<{id: string}>();

  const projectQuery = useQuery(["projectData", id], () =>
    projectService.getProject(id),
  );

  const statusHistoryQuery = useQuery(["projectHistoryStatusData", id], () =>
    adminService.getProjectStateHistory(id),
  );

  const statusHistoryList = statusHistoryQuery.data?.data ?? [];
  const projectDto: ProjectDto | undefined = projectQuery.data?.data;
  const project = getProjectFromDto(projectDto);

  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["projectData", id]);

    queryClient.invalidateQueries(["projectHistoryStatusData", id]);
  };

  const lockProject = useMutation(adminService.lock, {
    onSuccess: () => invalidateQueries(),
  });
  const unlockProject = useMutation(adminService.unlock, {
    onSuccess: () => invalidateQueries(),
  });
  const updateRemainingRenders = useMutation(
    adminService.updateRemainingRenders,
    {
      onSuccess: () => invalidateQueries(),
    },
  );

  const onLock = (id: string) => {
    lockProject.mutate(id);
  };

  const onUnlock = (id: string) => {
    unlockProject.mutate(id);
  };

  const onUpdateRemainingRenders = (id: string, remainingRenders: number) => {
    updateRemainingRenders.mutate({id, remainingRenders});
  };

  const increaseRemainingRenders = () => {
    onUpdateRemainingRenders(
      id,
      Math.max((project?.remainingRenders ?? 0) + 1, 1),
    );
  };

  const decreaseRemainingRenders = () => {
    onUpdateRemainingRenders(
      id,
      Math.max((project?.remainingRenders ?? 0) - 1, 0),
    );
  };

  const Icon = getProjectStateIcon(project?.state);

  return (
    <TimelineContextProvider project={project}>
      <Header />

      {projectQuery.isError ||
        (statusHistoryQuery.isError && (
          <Alert
            message="Failed to fetch the projects description or history"
            description="Please try again later."
            type="error"
            closable
          />
        ))}

      <Content>
        <Title>Project State History</Title>

        <Descriptions title="" bordered>
          <Descriptions.Item label="Song Title">
            {project?.songTitle}{" "}
            <a
              href={`${project.songUrl}`}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <SoundOutlined />
            </a>{" "}
            {project?.videoUrl && (
              <a
                href={`${project?.videoUrl}`}
                target={"_blank"}
                rel={"noopener noreferrer"}
                title={
                  project.videoUrlHost === VideoUrlHost.CLOUDINARY
                    ? "Link to Cloudinary video file"
                    : project.videoUrlHost === VideoUrlHost.S3
                    ? "Link to S3 video file"
                    : "Link to video file"
                }
              >
                <PlayCircleOutlined />
              </a>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="State">
            <Row>
              <Icon /> {project?.state.toLowerCase()}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Created">
            {project?.createdAt && (
              <RelativeDateTime dateTime={new Date(project.createdAt)} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Email">
            {project?.customerEmail}
          </Descriptions.Item>

          <Descriptions.Item label="State Detail">
            {statusHistoryList?.[0]?.detail ?? ""}
          </Descriptions.Item>

          <Descriptions.Item label="Updated">
            {project?.updatedAt && (
              <RelativeDateTime dateTime={new Date(project.updatedAt)} />
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Edit">
            {project?.state === ProjectState.inprogress ? (
              <EditOutlined />
            ) : (
              <Link to={`/?projectId=${id}`}>
                <EditOutlined />
              </Link>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Lock/Unlock/Fix">
            <LockUnlockFixButton
              project={project}
              onLock={() => onLock(project.id)}
              onUnlock={() => onUnlock(project.id)}
              onFix={() => onLock(project.id)}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Remaining renders">
            {project?.remainingRenders}{" "}
            <Button
              title="Increase remaining renders"
              onClick={increaseRemainingRenders}
            >
              <PlusOutlined color={"#1890ff"} />
            </Button>
            <Button>
              <MinusOutlined
                title="Decrease remaining renders"
                onClick={decreaseRemainingRenders}
              />
            </Button>
          </Descriptions.Item>
        </Descriptions>

        <Flex>
          <ValidityDisplay />
        </Flex>

        <Timeline>
          {statusHistoryQuery.isFetching ? (
            <>
              <Timeline.Item
                dot={<ClockCircleOutlined spin style={{fontSize: "16px"}} />}
              >
                <P>created</P>
                <P>loading...</P>
              </Timeline.Item>
            </>
          ) : (
            statusHistoryList.map((item, index) => {
              const Icon = getProjectStateIcon(item.state);
              return (
                <Timeline.Item
                  key={item.timestamp + index}
                  dot={<Icon style={{fontSize: "16px"}} />}
                >
                  <P>
                    {item.state.toLowerCase()},{" "}
                    {item?.detail && `${item.detail}, `}
                    <RelativeDateTime dateTime={new Date(item.timestamp)} />
                  </P>
                </Timeline.Item>
              );
            })
          )}
        </Timeline>
      </Content>
      <Footer />
    </TimelineContextProvider>
  );
};

const Button = styled(GhostButton)`
  color: #1890ff;
`;

const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 0 1rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${rem(768)}) {
    max-width: ${rem(849)};
  }
`;

const Title = styled(Typography.Title)`
  && {
    color: #640073;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
