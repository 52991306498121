import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const TimelineBox = styled.li<{
  isActive?: boolean;
  isOutstanding?: boolean;
}>`
  cursor: move;
  display: inline-flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;

  position: relative;
  border: 1px solid #000000;
  border-radius: 0;

  img {
    max-width: 100%;
    margin: auto;
    color: #f64d1c;
    clear: both;
    pointer-events: none;
  }
`;

export const TimelineBoxGrid = styled(TimelineBox)<{
  isActive?: boolean;
  isOutstanding?: boolean;
}>`
  position: relative;
  cursor: move;

  transition: transform 1s ease-in-out;
  user-select: none;

  &:hover {
    background: #d7d2b8;
  }

  &.sortable-swap-highlight {
    border: 1px dashed #000000;
    transform: scale(0.975);
    opacity: 0.8;
  }

  ${({isActive}) =>
    isActive &&
    css`
      box-shadow: 0 5px #d7d2b8;
      border: 1px solid #444444;
      transform: perspective(1rem);
    `}

  ${({isOutstanding}) =>
    isOutstanding &&
    css`
      opacity: 0.5;
      border: 1px dashed #000000;
    `}

  &.sortable-chosen {
    background: #d4d2c4;

    .icon--remove {
      visibility: hidden;
    }
  }

  &.sortable-drag {
    background: #d4d2c4;
  }
`;

export const TimelineBoxList = styled(TimelineBox)<{
  href?: string;
  isActive?: boolean;
  isOutstanding?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  height: 4rem;
  line-height: 4rem;
  border: none;

  box-shadow: 0 0.4375rem #f2f0e6, 0 0.5rem #cfcfcf, 0 -0.4375rem #f2f0e6,
    0 -0.5rem #cfcfcf;

  ${({isActive}) =>
    isActive &&
    css`
      box-shadow: 0 0.4375rem #f2f0e6, 0 0.5rem #000000, 0 -0.4375rem #f2f0e6,
        0 -0.5rem #000000;
    `}

  &.sortable-swap-highlight {
    .icon--remove {
      visibility: hidden;
    }
  }
  &.sortable-chosen {
    .icon--remove {
      visibility: hidden;
    }
  }
`;

export const TimelineBoxBorder = styled.div<{
  isActive?: boolean;
  isOutstanding?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  width: 100%;

  transition: all 1s;
  transform: scale(1);
  opacity: 1;

  .sortable-swap-highlight & {
    border: 1px solid #000000;
    transform: scale(1.0075);
  }

  .sortable-chosen & {
    border: 1px solid #f64d1c;
  }

  ${({isOutstanding}) =>
    isOutstanding &&
    css`
      opacity: 0.5;
      border: 1px dashed #e5e5e5;
    `}
`;
