import React from "react";
import {SkeletonTheme} from "react-loading-skeleton";
import {useQuery} from "react-query";
import {Redirect} from "react-router-dom";
import {
  GetDurationBetweenContextProvider,
  PlayerContextProvider,
} from "../hooks/PlayerContext";
import {Timeline} from "../components/timeline/Timeline";
import {Grid} from "../components/Grid";
import {HeaderWithBackToDashboardButton} from "../components/Header";
import {Footer} from "../components/Footer";
import {
  getProjectFromDto,
  ProjectDto,
  projectService,
} from "../service/projectService";
import {ProjectState} from "../components/AdminTable";
import {
  getTokenProjectId,
  isAdminTokenValid,
  isTokenValid,
  isUserTokenValid,
  setToken,
  useUrlQuery,
} from "../helpers/token";
import {TimelineContextProvider} from "../hooks/TimelineContext";

export const TimelinePageAuthInterceptor = () => {
  const query = useUrlQuery();

  const token = query.get("token") || "";
  if (token && isTokenValid(token)) {
    setToken({token});
  }

  if (!isTokenValid()) {
    return <Redirect to="/session-expired" />;
  }

  const isUser = isUserTokenValid();
  const isAdmin = isAdminTokenValid();

  const projectId: string = isUser
    ? getTokenProjectId()
    : query.get("projectId") || "";

  if (isAdmin && !projectId) {
    return <Redirect to="/admin" />;
  }

  if (!projectId) {
    throw new Error("Missing projectId");
  }

  return <TimelinePage projectId={projectId} />;
};

export const TimelinePage = ({projectId}: {projectId: string}) => {
  const {error, data} = useQuery(["projectData", projectId], () =>
    projectService.getProject(projectId),
  );

  const isUser = isUserTokenValid();

  if (error) {
    throw error;
  }

  const projectDto: ProjectDto | undefined = data?.data;
  const project = getProjectFromDto(projectDto);

  if (
    isUser &&
    [ProjectState.inprogress, ProjectState.failed].includes(project.state)
  ) {
    return <Redirect to="/in-progress" />;
  }

  if (isUser && [ProjectState.locked].includes(project.state)) {
    return <Redirect to="/in-revision" />;
  }

  if (isUser && project.remainingRenders === 0) {
    return <Redirect to="/no-more-video-renders" />;
  }

  return (
    <SkeletonTheme color="#f2f0e6" highlightColor="#d7d2b8">
      <PlayerContextProvider songUrl={project.songUrl}>
        <TimelineContextProvider project={project}>
          <GetDurationBetweenContextProvider>
            <>
              <HeaderWithBackToDashboardButton projectState={project.state} />
              <Grid>
                <Timeline />
              </Grid>

              <Footer />
            </>
          </GetDurationBetweenContextProvider>
        </TimelineContextProvider>
      </PlayerContextProvider>
    </SkeletonTheme>
  );
};
