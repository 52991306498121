import React from "react";
import {BasePage} from "./BasePage";
import {useTitle} from "react-use";
import styled from "@emotion/styled";
import {rem} from "polished";
import {P} from "../components/Typography";

const defaultProps = {
  ...BasePage.defaultProps,
  LogoWrapper: styled.div`
    display: grid;

    grid-row: 1;
    grid-column: 1;
  `,
  LargeLogo: styled(BasePage.defaultProps.LargeLogo)`
    &.a,
    &.b,
    &.c {
      opacity: 0;
    }
    position: relative;

    @keyframes fadeinphotoA {
      0% {
        opacity: 0;
      }
      15% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fadeinphotoB {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fadeinphotoC {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      85% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &.a {
      animation: fadeinphotoA 8s ease-in 0s infinite;
    }

    &.b {
      margin-top: -15rem;
      @media screen and (min-width: ${rem(1024)}) {
        margin-top: -30rem;
      }

      animation: fadeinphotoB 8s ease-in 0s infinite;
    }

    &.c {
      margin-top: -15rem;
      @media screen and (min-width: ${rem(1024)}) {
        margin-top: -30rem;
      }
      animation: fadeinphotoC 8s ease-in 0s infinite;
    }
  `,
};

export type Props = typeof defaultProps;

export const InProgressPage = ({
  Header,
  H1,
  Wrapper,
  Footer,
  LogoWrapper,
  LargeLogo,
  ReturnButton,
  PageFooter,
}: Props) => {
  const title = "We are creating your photo slideshow";

  useTitle(title);

  return (
    <>
      <Header />

      <Wrapper>
        <LogoWrapper>
          <LargeLogo
            className="a"
            src="/img/Wait_01.png"
            alt="Creating photo-album"
          />
          <LargeLogo
            className="b"
            src="/img/Wait_02.png"
            alt="Receive email"
          />
          <LargeLogo
            className="c"
            src="/img/Wait_03.png"
            alt="Receive video"
          />
        </LogoWrapper>

        <H1>{title}</H1>

        <Footer>
          <P>
            We're making your photo slideshow high resolution, adding smooth
            transitions between photos and syncing your personalized song to it,
            which takes around 30-60 minutes. You can close this tab and we will
            send you an email as soon as it's finished and ready to watch.
          </P>

          <ReturnButton />
        </Footer>
      </Wrapper>

      <PageFooter />
    </>
  );
};

InProgressPage.defaultProps = {
  ...defaultProps,
};
