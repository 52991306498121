import React, {PropsWithChildren} from "react";
import {Button} from "../Button";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {rem} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagic, faFileImage} from "@fortawesome/free-solid-svg-icons";
import {
  ItemMetric,
  useExtendedTimelineMetrics,
} from "../../helpers/getTimelineGapMetrics";
import {useTimelineContext} from "../../hooks/TimelineContext";

export const Upload = ({
  metrics,
  onUpload,
}: {
  isActive: boolean;
  index: number;
  metrics: ItemMetric;
  onUpload?: () => void;
}) => {
  const {submittedAt} = useTimelineContext();
  const {isValid} = useExtendedTimelineMetrics();
  const isLooped = isValid && metrics === ItemMetric.empty;
  const isInvalid =
    !isValid &&
    [ItemMetric.empty, ItemMetric.gap].includes(metrics) &&
    Boolean(submittedAt);

  return (
    <UploadButtonWrapper>
      <FileImageIcon
        isGap={metrics === ItemMetric.gap}
        isLooped={isLooped}
        isInvalid={isInvalid}
      />
      <Button onClick={onUpload}>Choose File</Button>
      <Description>No file chosen</Description>
    </UploadButtonWrapper>
  );
};

export const UploadButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;

  justify-content: center;
  padding: 0 1rem;
  margin: 0.6875rem 0;

  height: calc(100vw * (1 / 4) + 2rem);

  @media screen and (min-width: ${rem(768)}) {
    height: calc(100vw * (1 / 6) + 2rem);
  }

  @media screen and (min-width: ${rem(1200)}) {
    height: calc(${rem(1024)} * (1 / 8) + 2rem);
  }

  button {
    margin: 0.25rem 0;
  }
`;

export const Description = styled.span`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
`;

const FileImageIcon = styled(
  ({
    isInvalid,
    isGap,
    isLooped,
    ...props
  }: PropsWithChildren<{
    isInvalid?: boolean;
    isGap?: boolean;
    isLooped?: boolean;
  }>) => (
    <div {...props}>
      <FontAwesomeIcon icon={isLooped ? faMagic : faFileImage} />
    </div>
  ),
)`
  color: rgba(0, 0, 0, 0.5);
  display: inline-flex;
  justify-content: center;
  font-size: 2rem;
  padding: 1rem;
  border: 0.5rem solid rgba(255, 255, 255, 0.5);

  ${({isLooped}) => isLooped && css``}

  ${({isGap, isInvalid}) =>
    (isGap || isInvalid) &&
    css`
      color: rgba(246, 77, 28, 0.5);
    `}

  ${({isGap, isInvalid}) =>
    isGap &&
    isInvalid &&
    css`
      color: rgba(246, 77, 28, 0.5);
      border-color: rgba(246, 77, 28, 0.125);
      color: #f64d1c;
    `}
`;
