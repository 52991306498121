import React from "react";
import styled from "@emotion/styled";

import {BasePage} from "./BasePage";
import {useTitle} from "react-use";

const defaultProps = {
  ...BasePage.defaultProps,
  H1: styled(BasePage.defaultProps.H1)`
    color: #f64d1c;
  `,
};

export type Props = typeof defaultProps;

export const NoMatchPage = ({
  Header,
  H1,
  Wrapper,
  Footer,
  LargeLogo,
  ReturnButton,
  PageFooter,
}: Props) => {
  const title = "Page Not Found";

  useTitle(title);

  return (
    <>
      <Header />
      <Wrapper>
        <LargeLogo src="/img/Error.png" alt="404 page not found" />

        <H1>{title}</H1>

        <Footer>
          The page you were looking for could not be found. It might have been
          removed, renamed, or did not exist in the first place.
          <ReturnButton />
        </Footer>
      </Wrapper>

      <PageFooter />
    </>
  );
};

NoMatchPage.defaultProps = {
  ...defaultProps,
};
