import React, {ReactNode} from "react";
import {Redirect} from "react-router-dom";

type ErrorBoundaryState = {hasError: boolean; error: Error | null};
type ErrorBoundaryProps = {
  children: ReactNode;
};

const defaultState: ErrorBoundaryState = {hasError: false, error: null};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public state = defaultState;

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {hasError: true, error};
  }

  render() {
    return this.state.hasError ? (
      <Redirect
        to={`/oops?message=${encodeURI(this.state.error?.message || "")}`}
      />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
