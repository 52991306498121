import React from "react";
import {InProgressPage} from "./InProgressPage";
import {useTitle} from "react-use";
import {A, H1, H2, H3, H4, Label, P} from "../components/Typography";
import styled from "@emotion/styled";
import {BasePage} from "./BasePage";
import {
  BaseButton,
  Button,
  ButtonSecondary,
  CtaButton,
} from "../components/Button";

const defaultProps = {
  ...InProgressPage.defaultProps,
  Wrapper: styled(InProgressPage.defaultProps.Wrapper)`
    justify-content: left;
    align-items: flex-start;
  `,
  H1Orange: styled(BasePage.defaultProps.H1)`
    color: #f64d1c;
  `,
};

export type Props = typeof defaultProps;

export const StyleguidePage = ({
  Header,
  H1Orange,
  Wrapper,
  LogoWrapper,
  LargeLogo,
  PageFooter,
}: Props) => {
  const title = "Styleguide";

  useTitle(title);

  return (
    <>
      <Header />
      <Wrapper>
        <H1Orange>Styleguide</H1Orange>
        <H2>Typography</H2>
        <H1>H1 - Heading</H1>
        <H2>H2 - Heading</H2>
        <H3>H3 - Heading</H3>
        <H4>H4 - Heading</H4>

        <Label>label - Label</Label>
        <P>
          P - Paragraph Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </P>

        <A>a - link</A>

        <H2>Buttons</H2>

        <div>
          <BaseButton>BaseButton</BaseButton>
        </div>
        <div>
          <Button>Button</Button>
        </div>
        <div>
          <ButtonSecondary>ButtonSecondary</ButtonSecondary>
        </div>

        <div>
          <CtaButton>CtaButton</CtaButton>
        </div>

        <H2>Logos</H2>
        <LargeLogo src="/img/Error.png" alt="Slideshow Complications" />
        <LargeLogo src="/img/Revision.png" alt="Slideshow in revision" />
        <LargeLogo src="/img/Timeout.png" alt="Session Timeout" />
        <LargeLogo src="/img/Error.png" alt="404 page not found" />

        <LogoWrapper>
          <LargeLogo
            className="a"
            src="/img/Wait_01.png"
            alt="Creating photo-album"
          />
          <LargeLogo className="b" src="/img/Wait_02.png" alt="Receive email" />
          <LargeLogo className="c" src="/img/Wait_03.png" alt="Receive video" />
        </LogoWrapper>
      </Wrapper>

      <PageFooter />
    </>
  );
};

StyleguidePage.defaultProps = {
  ...defaultProps,
};
