import React from "react";
import {BasePage} from "./BasePage";
import {useTitle} from "react-use";
import styled from "@emotion/styled";
import {P} from "../components/Typography";

const defaultProps = {
  ...BasePage.defaultProps,
  H1: styled(BasePage.defaultProps.H1)`
    color: #f64d1c;
  `,
};

export type Props = typeof defaultProps;

export const SessionExpiredPage = ({
  Header,
  H1,
  Wrapper,
  Footer,
  LargeLogo,
  ReturnButton,
  PageFooter,
}: Props) => {
  const title = "Your session has expired";

  useTitle(title);

  return (
    <>
      <Header />
      <Wrapper>
        <LargeLogo src="/img/Timeout.png" alt="Session Timeout" />

        <H1>{title}</H1>

        <Footer>
          <P>Please return to the dashboard and open the page again.</P>

          <ReturnButton />
        </Footer>
      </Wrapper>

      <PageFooter />
    </>
  );
};

SessionExpiredPage.defaultProps = {
  ...defaultProps,
};
