import React from "react";
import "@atlaskit/css-reset";
import {Global, css} from "@emotion/react";
import {Router, Route, Switch} from "react-router-dom";
import {history} from "./helpers/history";
import {TimelinePageAuthInterceptor} from "./pages/TimelinePage";
import {AdminPageAuthInterceptor} from "./pages/AdminPage";
import {InProgressPage} from "./pages/InProgressPage";
import {InRevisionPage} from "./pages/InRevisionPage";
import {NoMatchPage} from "./pages/NoMatchPage";
import {ErrorPage} from "./pages/ErrorPage";
import ErrorBoundary from "./components/ErrorBoundary";
import {Layout} from "./components/Layout";
import {ReactQueryClientProvider} from "./hooks/QueryClientContext";
import {ReactQueryDevtools} from "react-query/devtools";
import {AdminStateHistory} from "./pages/AdminStateHistory";
import {SessionExpiredPage} from "./pages/SessionExpiredPage";
import {NoMoreVideoRenders} from "./pages/NoMoreVideoRenders";
import {StyleguidePage} from "./pages/StyleguidePage";

require("dotenv").config();

export const App = () => {
  return (
    <ReactQueryClientProvider>
      <Global
        styles={css`
          html,
          body,
          #root {
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-family: National-Book, sans-serif;
          }
        `}
      />

      <Layout>
        <Router history={history}>
          <Switch>
            <Route path="/oops">
              <ErrorPage />
            </Route>
            <Route path="/session-expired">
              <SessionExpiredPage />
            </Route>
            <Route path="/admin/project/:id/state-history">
              <ErrorBoundary>
                <AdminStateHistory />
              </ErrorBoundary>
            </Route>

            <Route path="/admin">
              <ErrorBoundary>
                <AdminPageAuthInterceptor />
              </ErrorBoundary>
            </Route>

            <Route path="/in-progress">
              <InProgressPage />
            </Route>

            <Route path="/in-revision">
              <InRevisionPage />
            </Route>

            <Route path="/no-more-video-renders">
              <NoMoreVideoRenders />
            </Route>

            <Route path="/styleguide">
              <StyleguidePage />
            </Route>

            <Route exact path="/">
              <ErrorBoundary>
                <TimelinePageAuthInterceptor />
              </ErrorBoundary>
            </Route>

            <Route path="*">
              <NoMatchPage />
            </Route>
          </Switch>
        </Router>
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryClientProvider>
  );
};

export default App;
