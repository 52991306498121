import React from "react";
import {BasePage} from "./BasePage";
import {useTitle} from "react-use";
import {P} from "../components/Typography";

const defaultProps = {
  ...BasePage.defaultProps,
};

export type Props = typeof defaultProps;

export const NoMoreVideoRenders = ({
  Header,
  H1,
  Wrapper,
  Footer,
  LargeLogo,
  ReturnButton,
  PageFooter,
  ContactUs,
}: Props) => {
  const title = "No more video renders..";

  useTitle(title);

  return (
    <>
      <Header />

      <Wrapper>
        <LargeLogo className="c" src="/img/Wait_03.png" alt={title} />

        <H1>{title}</H1>

        <Footer>
          <P>
            The video was rendered and sent to you in an email. You can find the
            video in dashboard. Due to security reasons we are limiting number
            of video renders. If you want to update your video please contact
            us.
          </P>

          <ReturnButton />
        </Footer>
        <ContactUs />
      </Wrapper>

      <PageFooter />
    </>
  );
};

NoMoreVideoRenders.defaultProps = {
  ...defaultProps,
};
