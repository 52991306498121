import axios from "./axiosInstance";
import {ProjectState} from "../components/AdminTable";
import {SortableAttributes} from "../components/timeline/Timeline";

export type TimelineItem = SortableAttributes & {
  id: string;
  assetId?: AssetId;
  publicId?: string;
};
export type TimelineListType = TimelineItem[];

export type AssetId = string;

export type Asset = Readonly<{
  assetId: AssetId; // "1389c34de9b0e8b5143475738545eca5",
  publicId: string; // "songfinch/cat2_kye6wu",
  version: number; // 1613330265,
  versionId: string; // "485ba8425b54d0b4b05c9631a467b57a",
  signature?: string; // "48bfd7c4c236b00205dd2e5bab31092573c8ace5",
  width?: number; //  300,
  height?: number; //  168,
  format?: string; // "jpg",
  bytes?: number; //  5836,
  resourceType?: string; // "image",
  createdAt?: string; // "2021-02-14T19:17:45Z",
}>;
export type Assets = Record<AssetId, Asset>;

export enum VideoUrlHost {
  CLOUDINARY = "CLOUDINARY",
  S3 = "S3",
}

export type TimelineItemDto = {
  assetId: string; // "d39b6ea9b17956245eac77038efd7af0"
  id: string; // "e0074bd5-47d7-4be9-a6d0-d3f164d47607"
  publicId: string; // "photo-timeline-stg/s4ueqlwmpzrda3rgt9uw"
};

export type ProjectTypeBase = {
  id: string;
  customerEmail: string;
  songTitle: string;
  artistName: string;
  songUrl: string;
  videoUrl: string | null;
  videoUrlHost: VideoUrlHost | null;
  state: ProjectState;
  createdAt: string; // "2021-03-21T12:31:28.614Z";
  updatedAt: string; // "2021-03-21T12:31:28.614Z";
  timeline: TimelineItemDto[] | null;
  remainingRenders: number;
};
export type ProjectDto = ProjectTypeBase &
  Readonly<{
    assets: Asset[] | null;
  }>;

export type Project = ProjectTypeBase &
  Readonly<{
    assets: Assets;
  }>;

export const getProjectFromDto = (projectDto?: ProjectDto): Project => {
  if (!projectDto) {
    return {
      id: "",
      customerEmail: "",
      songTitle: "",
      artistName: "",
      songUrl: "",
      videoUrl: "",
      videoUrlHost: null,
      state: ProjectState.created,
      createdAt: "",
      updatedAt: "",
      timeline: [],
      assets: {},
      remainingRenders: 3,
    };
  }

  return {
    ...projectDto,
    songUrl: projectDto?.songUrl ?? "",
    songTitle: projectDto?.songTitle ?? "",
    state: projectDto?.state ?? ProjectState.created,
    timeline: Array.isArray(projectDto?.timeline) ? projectDto.timeline : [],
    assets: (Array.isArray(projectDto?.assets) ? projectDto.assets : []).reduce(
      (assets: Assets, asset: Asset) => ({...assets, [asset.publicId]: asset}),
      {},
    ),
  };
};

export const projectService = {
  getProject: (projectId: string) =>
    axios.get<ProjectDto>(`api/projects/${projectId}`),
  postTimeline: ({
    projectId,
    timeline,
  }: {
    projectId: string;
    timeline: TimelineListType;
  }) => axios.post(`api/projects/${projectId}/timeline`, timeline),
  postAssets: ({projectId, assets}: {projectId: string; assets: Assets}) =>
    axios.post(`api/projects/${projectId}/assets`, Object.values(assets)),
  patchRender: ({projectId}: {projectId: string}) =>
    axios.patch(`api/projects/${projectId}/render`),
};
