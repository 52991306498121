import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useContext,
  useMemo,
} from "react";
import {secondsToMMSS} from "../helpers/time";
import {
  HTMLMediaControls,
  HTMLMediaProps,
  HTMLMediaState,
} from "react-use/lib/factory/createHTMLMediaHook";
import {useAudio} from "react-use";
import {SECTION_LENGTH_IN_SECONDS, useTimelineContext} from "./TimelineContext";

export type DurationBetweenContext = Readonly<{
  getDurationBetween: (index: number) => string;
}>;

export type Context = Readonly<{
  songUrl: string;
  audio: ReactElement;
  ref?: RefObject<HTMLAudioElement>;
  isLoadingAudio?: boolean;
  state: HTMLMediaState;
  controls: HTMLMediaControls;
  playing: boolean;
  togglePlaying: () => void;
  durationInSeconds: number;
  durationMMSS: string;
  playedInSeconds: number;
  playedMMSS: string;
}>;

export const GetDurationBetweenContext = createContext<DurationBetweenContext>(
  // @ts-ignore
  undefined,
);

export type AudioRef = HTMLAudioElement;

export const PlayerContextProvider = ({
  children,
  songUrl,
  config = {
    src: songUrl,
    autoPlay: false,
    controls: true,
    controlsList: "nodownload",
  },
}: PropsWithChildren<{
  songUrl: string;
  config?: HTMLMediaProps;
}>) => {
  const [audio, state, controls, ref] = useAudio(config);

  const value = useMemo(
    () => ({
      songUrl,
      audio,
      state,
      playing: !state.paused,
      togglePlaying: () => (state.paused ? controls.play() : controls.pause()),
      controls,
      ref,
      isLoadingAudio: Boolean(!state.duration),
      durationInSeconds: state.duration,
      durationMMSS: secondsToMMSS(state.duration),
      playedInSeconds: state.time,
      playedMMSS: secondsToMMSS(state.time),
    }),
    [songUrl, audio, state, controls, ref],
  );

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};

export const usePlayerContext = (): Context => useContext(PlayerContext);

export const GetDurationBetweenContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {durationInSeconds} = usePlayerContext();
  const {sectionsCount} = useTimelineContext();

  const valueDurationContext = useMemo(
    () => ({
      getDurationBetween: (index: number = 0) =>
        `${secondsToMMSS(
          Math.max(index * SECTION_LENGTH_IN_SECONDS, 0),
        )} - ${secondsToMMSS(
          sectionsCount - 1 !== index
            ? index * SECTION_LENGTH_IN_SECONDS + SECTION_LENGTH_IN_SECONDS
            : durationInSeconds,
        )}`,
    }),
    [durationInSeconds, sectionsCount],
  );

  return (
    <GetDurationBetweenContext.Provider value={valueDurationContext}>
      {children}
    </GetDurationBetweenContext.Provider>
  );
};

// @ts-ignore
export const PlayerContext = createContext<Context>({});
export const useGetDurationBetweenContext = (): DurationBetweenContext =>
  useContext(GetDurationBetweenContext);
