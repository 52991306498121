import styled from "@emotion/styled";
import {rem} from "polished";

export const Grid = styled.div`
  margin: 0;

  @media screen and (min-width: 321px) {
    margin: 0 1rem;
  }

  html {
    touch-action: manipulation;
  }

  @media screen and (min-width: ${rem(1200)}) {
    margin: auto;
    max-width: ${rem(1024)};
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0.25rem;

    @media screen and (min-width: ${rem(768)}) {
      padding-bottom: 2rem;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: ${rem(1200)}) {
      padding-bottom: 3rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    column-gap: 1rem;
    row-gap: 1rem;
  }

  .grid--list {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  ul,
  ol,
  dl {
    padding: 0;
  }

  li + li {
    margin: 0;
  }
`;
