import React from "react";
import styled from "@emotion/styled";

export const Layout = styled.div`
  min-height: 100vh;
  display: grid;
  background: #f2f0e6;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;
