import React from "react";
import styled from "@emotion/styled";
import {rem} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons";
import {
  useGetDurationBetweenContext,
  usePlayerContext,
} from "../hooks/PlayerContext";
import {Title} from "./Title";
import {getPlaceholderImage} from "./timeline/Timeline";
import {Image, Transformation} from "cloudinary-react";
import {cloud_name} from "../hooks/Cloudinary";
import {useTimelineContext} from "../hooks/TimelineContext";
import {TimelineBox} from "./timeline/TimelineBox";
import {GhostButton} from "./Button";

export const VideoPreview = () => (
  <Wrapper>
    <DurationTitle />

    <Picture />

    <Audio />
  </Wrapper>
);

export const Wrapper = styled(TimelineBox)`
  cursor: pointer;
  background: #f4f4f1;
  margin: 1rem auto 1rem auto;

  audio {
    width: 100%;
  }

  // noinspection CssInvalidPseudoSelector
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
    background: white;
    box-sizing: border-box;
  }
`;

const DurationTitle = () => {
  const {activeIndex} = useTimelineContext();
  const {getDurationBetween} = useGetDurationBetweenContext();
  const durationBetween = getDurationBetween(activeIndex);

  return <Title>{durationBetween}</Title>;
};
const Picture = () => {
  const {activeIndex, activeItem} = useTimelineContext();
  const {playing, togglePlaying} = usePlayerContext();

  const {getDurationBetween} = useGetDurationBetweenContext();

  const durationBetween = getDurationBetween(activeIndex);
  const nextDurationBetween = getDurationBetween(activeIndex + 1);

  const resolution = "600x400";
  const img = new Image();
  img.src = getPlaceholderImage({text: nextDurationBetween, resolution});

  return (
    <PreviewPictureWrapper onClick={togglePlaying}>
      <PlaySongButton playing={playing}>
        {!playing && <FontAwesomeIcon icon={faPlayCircle} />}
      </PlaySongButton>
      {activeItem?.publicId ? (
        <Image
          cloudName={cloud_name}
          public_id={activeItem.publicId}
          alt={durationBetween}
        >
          <Transformation
            width="300"
            height="200"
            gravity="faces"
            crop="fill"
          />
        </Image>
      ) : (
        <img
          src={getPlaceholderImage({text: durationBetween, resolution})}
          alt={durationBetween}
        />
      )}
    </PreviewPictureWrapper>
  );
};

export const PreviewPictureWrapper = styled(GhostButton)`
  img {
    width: 100%;
  }

  width: ${rem(270)};
  height: ${rem(180)};

  @media screen and (min-width: ${rem(321)}) {
    width: ${rem(300)};
    height: ${rem(200)};
  }

  @media screen and (min-width: ${rem(768)}) {
    width: ${rem(600)};
    height: ${rem(400)};
  }

  &:hover span {
    color: #ff9677;
  }
`;

export const PlaySongButton = styled.span<{playing?: boolean}>`
  position: absolute;

  padding: 0;
  background: none;

  font-size: 3em;
  color: #f64f1c;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
`;

const Audio = () => {
  const {audio} = usePlayerContext();

  return <>{audio}</>;
};
