import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {Label} from "./Typography";

export const Title = styled(Label)<{
  isActive?: boolean;
}>`
  width: 100%;
  text-align: center;
  transition: all 0.5s ease-in-out;
  font-family: National-Normal, sans-serif;

  ${({isActive}) =>
    isActive &&
    css`
      color: #000000;
    `}
`;
