import jwtDecode from "jwt-decode";
import {isPast, toDate} from "date-fns";
import qs from "qs";
import {useLocation} from "react-router-dom";

enum ScopeType {
  /**
   * Token for timeline.
   */
  USER = "USER",
  /**
   * Token for admin.
   */
  ADMIN = "ADMIN",
}

type Token = Readonly<{
  scope: ScopeType;
  sub: string; // "sub": "<Project ID>",
  iss: string; // "iss": "Songfinch web",
  exp: number; // "exp": "<Timestamp of expiration>",
  iat: number; // "iat:" "<Issue timestamp>"
}>;

export const TOKEN_KEY = "token";

export const getToken = (): string => localStorage.getItem(TOKEN_KEY) || "";
export const setToken = ({
  token,
  refreshedToken,
}: {
  token?: string;
  refreshedToken?: string;
}) => {
  if (token && isTokenValid(token)) {
    return localStorage.setItem(TOKEN_KEY, token);
  }

  if (refreshedToken) {
    const refreshedTokenString = refreshedToken.replace("Bearer ", "");

    if (isTokenValid(refreshedTokenString)) {
      return localStorage.setItem(TOKEN_KEY, refreshedTokenString);
    }
  }

  localStorage.setItem(TOKEN_KEY, "");
};

export const decodeToken = (token = getToken()) => jwtDecode<Token>(token);

const getTokenKey = (key: keyof Token) => (token = getToken()) =>
  decodeToken(token)[key];

// @ts-ignore
export const getTokenSub: (token?: string) => string = getTokenKey("sub");
export const getTokenProjectId = getTokenSub;

const getTokenSubjectType = getTokenKey("scope");
const getTokenExp = getTokenKey("exp");

const isTokenType = (subjectType: ScopeType) => (token = getToken()) =>
  getTokenSubjectType(token) === subjectType;

export const isUserToken = isTokenType(ScopeType.USER);

export const isAdminToken = isTokenType(ScopeType.ADMIN);

export const makeAuthorizationHeader = (token: string) => `Bearer ${token}`;

export const checkTokenValidity = (token = getToken()) => {
  try {
    jwtDecode(token as string);
    return true;
  } catch {
    return false;
  }
};

export const isTokenValid = (token = getToken()) => {
  return checkTokenValidity(token) && !tokenIsExpired(token);
};

export const isAdminTokenValid = (token = getToken()) => {
  return isTokenValid(token) && isAdminToken(token);
};

export const isUserTokenValid = (token = getToken()) => {
  return isTokenValid(token) && isUserToken(token);
};

export const tokenIsExpired = (token = getToken()) =>
  isPast(toDate(1000 * (getTokenExp(token) as number)));

export const getTokenFromLocation = (history: History): string | undefined => {
  // @ts-ignore
  const {token} = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }) as {token: string};

  return isTokenValid(token) ? token : undefined;
};

export const useUrlQuery = () => new URLSearchParams(useLocation().search);
