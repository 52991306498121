import styled from "@emotion/styled";
import {rem} from "polished";

export const BaseButton = styled.button`
  font-family: National-Bold, sans-serif;
  font-weight: 700;
  font-size: ${rem(19)};
  line-height: ${rem(21)};
  transition: all 0.3s;
  cursor: pointer;
  vertical-align: baseline;
`;

export const Button = styled(BaseButton)`
  color: #f2f0e6;
  background: #f64d1c;
  border: 1px solid transparent;
  padding: ${rem(5)} ${rem(10)};
  border-radius: 50vh;

  @media screen and (min-width: ${rem(768)}) {
    padding: ${rem(10)} ${rem(20)};
  }

  &:hover {
    color: white;
    transform: scaleX(1.05);
    background: #ff9677;
  }
  &:active {
    color: white;
    background: #cd4c27;
  }
  &:disabled {
    color: white;
    background: #cfcfcf;
  }
`;

export const ButtonSecondary = styled(Button)`
  background: #000000;
  color: white;

  &:hover {
    background: #444;
  }
  &:active {
    background: #9f9f9f;
  }
  &:disabled {
    background: #cfcfcf;
  }
`;

export const ButtonRegular = styled(ButtonSecondary)``;

export const CtaButton = styled(Button)`
  display: inline-block;
  text-align: center;
  width: 80%;
  max-width: ${rem(300)};
  min-height: ${rem(52)};
  margin: ${rem(30)} auto ${rem(40)};
  transition: all 0.3s;
  font-family: National-SemiBold, sans-serif;
  padding: 0 2em;
  border: 0;
  vertical-align: baseline;
`;

export const GhostButton = styled(BaseButton)`
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
`;
