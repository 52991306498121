import React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faThLarge} from "@fortawesome/free-solid-svg-icons";
import {GhostButton} from "../Button";
import {Label} from "../Typography";

export const ViewSwitcher = ({
  isListView,
  setIsListView,
}: {
  isListView: boolean;
  setIsListView: (is: boolean) => void;
}) => {
  return (
    <Wrapper>
      View:
      <SwitchButton isActive={isListView} onClick={() => setIsListView(true)}>
        <FontAwesomeIcon icon={faList} />
      </SwitchButton>
      <SwitchButton isActive={!isListView} onClick={() => setIsListView(false)}>
        <FontAwesomeIcon icon={faThLarge} />
      </SwitchButton>
    </Wrapper>
  );
};

const SwitchButton = styled(GhostButton)<{isActive?: boolean}>`
  color: #ffb5a0;
  padding: 0 0.5em;

  ${({isActive}) =>
    isActive &&
    css`
      color: #f64d1c;
    `}

  transition: all 0.3s;
  &:hover {
    color: #ff9677;
  }
`;

const Wrapper = styled(Label)`
  display: flex;
  column-gap: 0.2rem;
  row-gap: 1rem;
`;
