import React from "react";
import {
  ItemMetric,
  useExtendedTimelineMetrics,
} from "../../helpers/getTimelineGapMetrics";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faMagic,
  faMinus,
  faCheck,
  faCheckDouble,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {P} from "../Typography";

export const ValidityDisplay = () => {
  const {
    isValid,
    listMetrics,
    listLength,
    uploadsLength,
    gapIndexes,
    completedPercentage,
  } = useExtendedTimelineMetrics();

  return (
    <>
      <ProgressBar>
        {listMetrics.map((item, index) =>
          item === ItemMetric.upload ? (
            <Bar key={index} completedPercentage={100} />
          ) : item === ItemMetric.gap ? (
            <RedBar key={index} completedPercentage={100} />
          ) : (
            <GreyBar key={index} completedPercentage={100} />
          ),
        )}
      </ProgressBar>

      <ProgressBarFooter>
        <Info>
          <UploadedInfo title="# Uploaded photos">
            <FontAwesomeIcon icon={faCheck} /> {uploadsLength}
          </UploadedInfo>
          <MissingInfo title="# Invalid">
            <FontAwesomeIcon icon={faTimes} /> {gapIndexes.length}
          </MissingInfo>
          <LoopedInfo
            title={isValid ? "# of looped photos" : "# Available for upload"}
          >
            <FontAwesomeIcon icon={isValid ? faMagic : faMinus} />{" "}
            {listLength - uploadsLength}
          </LoopedInfo>
        </Info>
        <Completion isValid={isValid}>
          {isValid ? (
            uploadsLength === listLength ? (
              <FontAwesomeIcon icon={faCheckDouble} />
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            )
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          )}{" "}
          Completed {Math.ceil(completedPercentage || 0)}% ({uploadsLength}/
          {listLength})
        </Completion>
      </ProgressBarFooter>
    </>
  );
};

const ProgressBar = styled.div`
  background: #dee4f2;
  height: 1rem;
  display: flex;
  margin-top: 0.625rem;
  width: 100%;
`;

const Bar = styled.div<{completedPercentage: number}>`
  height: 100%;
  ${({completedPercentage}) =>
    css`
      width: ${completedPercentage}%;
    `}
  background: #000000;

  color: white;
  font-size: 0.625rem;
  text-align: center;
  vertical-align: center;
`;

const RedBar = styled(Bar)`
  background: #f64d1c;
`;

const GreyBar = styled(Bar)`
  background: #cfcfcf;
`;

const ProgressBarFooter = styled.div`
  display: flex;
`;

const CompletedLabel = styled(P)`
  //line-height: 1rem;
`;

const UploadedInfo = styled(CompletedLabel)`
  color: #000000;
`;

const MissingInfo = styled(CompletedLabel)`
  color: #f64d1c;
`;

const LoopedInfo = styled(CompletedLabel)`
  color: #cfcfcf;
`;

const Info = styled.div`
  display: flex;
  flex-grow: 1;
  column-gap: 0.616rem;
  row-gap: 0.616rem;
`;

const Completion = styled(CompletedLabel)<{isValid?: boolean}>`
  display: flex;
  flex-grow: 3;
  align-items: center;
  justify-content: flex-end;

  ${({isValid}) =>
    !isValid &&
    css`
      color: #f64d1c;
    `}
  svg {
    margin-right: 0.1875rem;
  }
`;
